import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../lib/apolloClient';
import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { MixpanelTrackingService } from '@/services/mixpanel-services';

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);

  datadogRum.init({
    applicationId: 'e6fe2e84-0d13-4e20-aa0c-c80f8631a60d',
    clientToken: 'pub0cbbb047ee3a9bab6e17ea08eda3b7d8',
    site: 'datadoghq.eu',
    service: 'teambertagneweb',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();

  useEffect(() => {
    MixpanelTrackingService.getInstance().pageViewed();
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <Component {...pageProps} />
    </ApolloProvider>
  );
}

export default MyApp;
