import mixpanel from 'mixpanel-browser';
import TrackingEvent from 'models/tracking-events.enum';

import getConfig from 'next/config';
const { serverRuntimeConfig } = getConfig();

export class MixpanelTrackingService {
  private _isInit = false;
  private static _instance: MixpanelTrackingService;

  public static getInstance(): MixpanelTrackingService {
    if (MixpanelTrackingService._instance == null)
      return (MixpanelTrackingService._instance =
        new MixpanelTrackingService());

    return this._instance;
  }

  public constructor() {
    if (MixpanelTrackingService._instance)
      throw new Error(
        'Error: Instance creation of MixpanelTrackingService is not allowed. Use MixpanelTrackingService.getInstance instead.',
      );

    try {
      console.log('Initialising mixpanel ...');

      mixpanel.init(serverRuntimeConfig.mixpanelID || '', {
        debug: true,
        ignore_dnt: true,
      });

      this._isInit = true;

      console.log('Mixpanel initialised successfully.');
    } catch (exc) {
      console.error('Error initialising mixpanel.', exc);
    }
  }

  protected track(name: string, data: object = {}) {
    if ((mixpanel as any).__loaded && this._isInit) {
      mixpanel.track(name, data);
    } else {
      console.warn('Mixpanel is not inilialized!');
    }
  }

  public pageViewed() {
    this.track(TrackingEvent.PAGE_VIEW);
  }
}
